// ================================================================================================
//  File Name: custom-rtl.scss
//  Description: RTL support SCSS file.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Variables
// ------------------------------

@import "bootstrap-extended/include"; // Bootstrap includes
@import "components/include"; // Components includes


.logout-btn {
    margin-left: -210px !important;
}
.active-number {
  color: $primary !important;

}
.Step-count {
  display: flex;
  align-items: center;
  // margin: 0 5px;
  color: #000;
font-feature-settings: 'clig' off, 'liga' off;
// font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
  
}
.sample {
  align-items: center;
  display: flex;
  margin-bottom: 0.2857rem;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;

}
.breadcrumb-separator {
  display: none !important;
}

.header-stepper {
  display: flex;
  justify-content: space-between;
}
.step {
  display: none !important;
}
.bs-stepper {
  margin-top: 24px;
}
.progress {
  width: 250px !important;
  height: 0.657rem !important;
}
.shadow-none {
  margin-top: 50px !important;
  display: flex !important;
  justify-content: end !important;
}
.drawer-icon {
  margin-left: 10px !important;
}
.add-btn {
  background-color: #36bf6d !important;
  border: #36bf6d;
}
.d-btn {
  // background-color: #36BF6D !important;
  border: 1px solid #36bf6d !important;
  color: #36bf6d !important;
  background-color: white !important;
}
.head-form-repeats2 {
  display: flex;
  // justify-content: center;
  margin: 20px 0 0 0 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  /* identical to box height, or 89% */

  color: #000000;
}
.available-col {
  display: contents !important;
}
.formrpeater-hr {
  height: 50px !important;
  margin: 0 11px;
  border: 1px solid;
}
.drawer-btn {
  margin-right: 10px;
  min-width: 25%;
  background-color: $primary !important;
}
.new-btn {
  padding: 14px 65px !important;
  margin: 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #ffffff;
  @media only screen and (max-width: 600px) {
    padding: 14px 50px !important;
  }
}
.new-btnc {
  padding: 14px 65px !important;
  margin: 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #c62828;
  @media only screen and (max-width: 600px) {
    padding: 14px 50px !important;
  }
}
.submit-col {
  position: fixed;
  bottom: 0px;
}
.palceho-color {
  .select__control .select__placeholder,
  .react-select__control .select__placeholder {
    color: #6e6b7b70 !important;
  }
}
.expand-more-icon {
  transform: rotate(180deg);
}
.user-card {
  margin: 0 35px;
  box-shadow: none !important;
}
.close-icon {
  cursor: pointer;
}
.user-cardheader {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 35px 35px 0 35px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 132%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  color: #ffffff;
}
.user-cardhead {
  // margin: 0 35px ;
  padding: 1.5rem 35px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
}
.drawer {
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper,
  .css-1ab2xsx {
    min-width: 478px !important;
    max-width: 478px !important;

    @media only screen and (max-width: 600px) {
      min-width: 400px !important;
      max-width: 400px !important;
    }
  }
}
.list-border {
  // border-top: 1px solid black;
  border-bottom: 0.5px solid #6e6b7b1f;
  margin: 0;
  list-style-type: none;
  padding: 10px 30px;
  justify-content: space-between;
}
.list-border-h {
  // border-top: 1px solid black;
  // border-bottom: 0.5px solid #6e6b7b1f;
  margin: 0;
  list-style-type: none;
  // padding: 10px 30px;
  // justify-content: space-between;
}
.cd-custom-table {
  // min-width: 914px;
  overflow: scroll;
  // overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}
.li-2 {
  background: #ededed;
  /* text-align: center; */
  border-radius: 50px;
  /* align-content: center; */
  display: flex;
  justify-content: center;
  /* margin: 5px; */
  padding: 4px 8px 4px 8px;
}

.access-b {
  margin-top: -400px !important;
}
.p-currunt {
  font-size: 12px;
}
textarea {
  resize: none;
}
.value-input {
  width: 270px !important;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  border: 0px solid #d8d6de !important;
  // background-color: white !important;
  // // font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #000000;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #9c9999;
}
.logo-b {
  margin-bottom: 50px !important;
}
.customHeader {
  background-color: #bbab82;
  display: flex;
  // justify-content: space-between;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 1.5rem;
  color: white;
  // font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  // color: #ffffff;
}
.CustomWidth {
  display: block;

  width: auto;
  align-items: center;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
}
.cutom1 {
  width: auto;
  display: block;

  align-items: center;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
}
.CustomWidth2 {
  width: 20%;
  align-items: center;
  display: block;
  // margin-right: 0.7rem;
  // margin-bottom: 0.7rem;
}
.cutom12 {
  width: 20%;
  display: block;

  align-items: center;
  // margin-right: 0.7rem;
  // margin-bottom: 0.7rem;
}
.gg {
  width: auto;
  align-items: center;
  // margin-right: 0.7rem;
  margin-bottom: 0.4rem;
  display: flex;
  position: absolute;
    background-color: #36bf6d;
    border-radius: 50%;
    height: auto;
    width: auto;
    left: 91%;
    top: 3%;
    padding: 9px;
}
.CustomWidths {
  width: auto;
  align-items: center;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
  display: flex;
}
.cutom1s {
  width: auto;
  display: flex;
  align-items: center;
  // margin-right: 0.7rem;
  // margin-bottom: 0.7rem;
}
.CustomWidth3 {
  width: 35%;
  display: block;

  align-items: center;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
}
.cutom13 {
  width: 20%;
  display: block;

  align-items: center;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
}
.CustomWidth3H {
  width: 40%;
  display: flex;

  align-items: center;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
}
.cutom13H {
  width: 40%;
  display: flex;

  align-items: center;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
}

.customField {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.customFieldM {
  display: flex;
  // justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.input-bg {
  background-color: #eff0f6 !important;
  border: 0 !important;
  padding: 0.871rem 1rem !important;
}
.mobile-label {
  // font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */
  margin-left: 5px;
  display: none;
  margin-top: 10px;
  margin-bottom: 10px;

  color: #000000;
}
.hr-css {
  // border: 1px solid black;
  width: 100%;
  display: none;
  // justify-content: center;
}
@media only screen and (max-width: 425px) {
  .mobile-view-dis {
    display: none;
  }
  .CustomWidth2 {
    width: 70% !important;
  }
  .cutom12 {
    width: 70% !important;
  }
  .CustomWidth {
    width: 100% !important;
  }
  .cutom1 {
    width: 100% !important;
  }
  .CustomWidth3 {
    width: 100% !important;
  }
  .cutom13 {
    width: 100% !important;
  }
  .mobile-view {
    display: flex;
    flex-wrap: wrap;
  }
  .mobile-label {
    display: inherit;
  }
  .hr-css {
    // border: 1px solid black;
    width: 100%;
    display: flex;
    // justify-content: center;
  }
  .value-input {
    border: 0;
  }
}

.route-menu {
  margin-top: 50px !important;
}
pre,
code,
kbd,
samp {
  direction: ltr;
}
// Align icons position
.main-menu {
  .navigation li > a > svg,
  .navigation li > a > i,
  .dropdown-menu svg,
  .dropdown-menu i,
  .dropdown-user > a > svg,
  .dropdown-user > a > i,
  .navigation > li > a > svg,
  .navigation > li > a > i {
    float: right;
  }

  .navigation > li ul li > a {
    display: flex;
    align-items: center;
  }
}
// [type="tel"],
// [type="url"],
// [type="email"],
// [type="number"] {
//   direction: rtl;
// }

// Transformed menu icons
.vertical-layout.vertical-menu-modern.menu-expanded
  .main-menu
  .navigation
  li.has-sub
  > a:after,
.vertical-layout.vertical-overlay-menu.menu-open
  .main-menu
  .navigation
  li.has-sub
  > a:after {
  transform: rotate(180deg);
}
.vertical-layout.vertical-menu-modern.menu-expanded
  .main-menu
  .navigation
  li.has-sub.open:not(.menu-item-closing)
  > a:after {
  transform: rotate(90deg);
}

// Horizontal menu
.horizontal-menu
  .header-navbar.navbar-horizontal
  .dropdown-menu
  .dropdown-toggle::after {
  background-image: url(str-replace(
    str-replace($chevron-left, "currentColor", $body-color),
    "#",
    "%23"
  ));
}

// Dropdown RTL Changes
.header-navbar .navbar-container ul.nav li.dropdown {
  .dropdown-menu {
    &::before {
      top: 1px;
    }
  }
}
.repeater-responsive {
  min-width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none !important;
  }

  &_body {
    max-height: 100%;
    // overflow: auto
  }
}
@media only screen and (max-width: 425px) {
  .repeater-responsive {
    min-width: 300px;
  }
}
.repeater-overflow {
  overflow: auto;
}
.header-navbar {
  .dropdown,
  .dropup {
    .dropdown-menu.dropdown-menu-end::before {
      right: auto;
      left: 0.5rem;
    }
  }
}

.dropdown,
.dropup,
.btn-group {
  .dropdown-menu {
    right: auto !important;
    left: auto !important;

    &.dropdown-menu-end {
      left: 0 !important;

      &::before {
        right: 0.6rem;
        left: auto;
      }
    }
  }
}

.dropstart {
  .dropdown-toggle {
    &::before {
      background-image: url(str-replace(
        str-replace($chevron-right, "currentColor", $white),
        "#",
        "%23"
      )) !important;
    }
  }
  .dropdown-menu {
    margin-right: $dropdown_spacing !important;
  }
}

.dropend {
  .dropdown-toggle {
    &::after {
      background-image: url(str-replace(
        str-replace($chevron-left, "currentColor", $white),
        "#",
        "%23"
      )) !important;
    }
  }
  .dropdown-menu {
    left: 0 !important;
    margin-left: $dropdown_spacing !important;
  }
}

// BS Toast
.toast {
  right: auto;
}

// Select2
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  float: right;
}
.select2-search__field {
  direction: rtl;
}
.select2,
.select2-container {
  text-align: right;
}

.apexcharts-canvas .apexcharts-text.apexcharts-yaxis-label {
  transform: translate(14px, 0);
}

// Chartist
.chartjs-render-monitor {
  margin-right: 1rem;
}

// Datatable
div.dataTables_wrapper div.dataTables_filter {
  text-align: left !important;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before {
  right: 0.45rem;
}

// Datatable responsive modal
.dtr-modal .dtr-modal-close {
  left: 6px;
  right: auto !important;
}

// Avatar group
.avatar-group {
  // Avatar Group Sizings
  .avatar {
    margin-right: -0.785rem;
    margin-left: 0;
  }
  .avatar-sm {
    margin-right: -0.65rem;
  }
  .avatar-lg {
    margin-right: -1.5rem;
  }
  .avatar-xl {
    margin-right: -1.85rem;
  }
}

// Breadcrumb
.breadcrumb:not([class*="breadcrumb-"]),
.breadcrumb.breadcrumb-chevron {
  .breadcrumb-item + .breadcrumb-item {
    &:before {
      transform: rotate(180deg);
    }
  }
}

// Pagination
// .pagination .page-item {
//   &.prev-item,
//   &.prev,
//   &.previous {
//     .page-link {
//       &:before {
//         transform: rotate(180deg);
//       }
//       &:hover,
//       &:active {
//         &:before {
//           transform: rotate(180deg);
//         }
//       }
//     }
//     &.disabled {
//       .page-link {
//         &:before {
//           transform: rotate(180deg);
//         }
//       }
//     }
//   }

//   &.next-item,
//   &.next {
//     .page-link {
//       &:after {
//         transform: rotate(180deg);
//       }
//       &:hover,
//       &:active {
//         &:after {
//           transform: rotate(180deg);
//         }
//       }
//     }
//     &.disabled {
//       .page-link {
//         &:before {
//           transform: rotate(180deg);
//         }
//       }
//     }
//   }
// }

code[class*="language-"],
pre[class*="language-"] {
  direction: ltr;
}

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

// Calendar
.fc .fc-header-toolbar .fc-right .fc-button.fc-prev-button .fc-icon {
  right: 4px !important;
}

.fc .fc-header-toolbar .fc-right .fc-button.fc-next-button .fc-icon {
  left: -3px !important;
}

// Popover & Tooltip
.bs-popover-start .popover-arrow::before,
.bs-tooltip-start .tooltip-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: $tooltip-bg;
  left: -1px;
}
.bs-tooltip-start .tooltip-arrow::before {
  left: -6px;
}
.bs-popover-start > .popover-arrow::after,
.bs-tooltip-start > .tooltip-arrow::after {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: $tooltip-color;
}
.bs-popover-start .popover-arrow,
.bs-tooltip-start .tooltip-arrow,
.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
  right: auto;
  left: 100%;
}

.bs-popover-end .popover-arrow::before,
.bs-tooltip-end .tooltip-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: $tooltip-bg;
  right: -1px;
}
.bs-tooltip-end .tooltip-arrow::before {
  right: -6px;
}
.bs-popover-end > .popover-arrow::after,
.bs-tooltip-end > .tooltip-arrow::after {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: $tooltip-color;
}
.bs-popover-end .popover-arrow,
.bs-tooltip-end .tooltip-arrow,
.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
  left: auto;
  right: 100%;
}

// Perfect scrollbar RTL fix
body .ps__rail-y {
  right: auto !important;
  left: 1px !important;
}

// FAQ and Pricing page
.faq-navigation img,
.pricing-free-trial .pricing-trial-img {
  transform: scaleX(-1);
}

.feather-chevron-left,
.feather-chevron-right {
  transform: rotate(-180deg) !important;
}

// Kanban
.kanban-application {
  .kanban-container {
    .kanban-item {
      i,
      svg {
        margin-right: 0 !important;
        margin-left: 0.25rem;
      }
    }
  }
}

// Invoice List
.invoice-list-wrapper {
  .dataTables_filter {
    input {
      margin-left: 0 !important;
      margin-right: 0.5rem;
    }
  }

  .dropdown .dropdown-menu.dropdown-menu-end {
    left: 2rem !important;
  }
}

// File Manager
.file-manager-application {
  .sidebar-file-manager {
    .sidebar-inner {
      .my-drive .jstree-node.jstree-closed > .jstree-icon {
        transform: rotate(180deg);
      }
    }
  }
}

//side bar linse

.sideline {
  border-left: 1.5px solid #ffffff;
  /* height: 80px; */
  height: calc(100% - 97px);
  position: absolute;
  left: 44px;
  top: 61px;
}
.navbarchildicon {
  // margin-right: 16px;
  margin-bottom: 10px;
  margin-left: 9px;
}

//colapser
.collapsToggler {
  height: 29px;
}
.rotate {
  transform: rotate(180deg);
}

//sidebar logo

.brand-logo-small {
  margin-left: -107px;
  figure {
    svg {
      height: 60px !important;
    }
  }
}

//add button in all listing
.addButton {
  margin-left: 16px;
  // background-color: #04244a !important;
  background-color: white !important;
  border: 1px solid #04244a;
  color: #04244a !important;

  .content {
    margin-left: 6px;
    font-weight: 600;
  }
  &:focus {
    background-color: white !important;
    // background-color: #04162c !important;
    border: 1px solid #04162c;
    color: #04162c !important;
    box-shadow: none !important;
  }
  &:hover {
    // background-color: #1f497c !important;
    background-color: white !important;
    border: 1px solid #1f497c;
    box-shadow: none !important;
    color: #1f497c !important;
  }
}


@media (max-width: 767px) {
  .header-stepper {
    display: block !important;
  }
  .progress {
    width: 129px !important;
    height: 0.657rem !important;
  }
  
 }


